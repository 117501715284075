import { useAlert } from 'hooks/useAlert'
import { User } from 'modules/users/domain/entities/User'
import { useUser } from 'modules/users/presentation/contexts/UserContext'
import { UserFormInputs } from 'modules/users/presentation/validations/UserFormValidation'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { formatErrorMessage, ErrorMessage } from 'utils/errorUtils'
import { sanitizeValue } from 'utils/sanitizeObject'

import { UserFormPresentation } from '../presentational/UserFormPresentation'

interface UserFormContainerProps {
  isOpen: boolean
  onClose: () => void
  user?: User
}

export const UserFormContainer = ({
  isOpen,
  onClose,
  user
}: UserFormContainerProps) => {
  const isEditing = !!user
  const { alert } = useAlert()

  const {
    roles,
    isLoadingRoles,
    createUser,
    updateUser,
    isLoadingCreate,
    isLoadingUpdate,
    updateUsersList
  } = useUser()

  const {
    watch,
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<UserFormInputs>()

  const username = watch('username')?.trim().length >= 1
  const login = watch('login')?.trim().length >= 1
  const isValidFields = isEditing ? username : username && login

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'username' || name === 'login') {
        const sanitizedValue = sanitizeValue(value[name])
        if (sanitizedValue !== value[name]) {
          setValue(name as keyof UserFormInputs, sanitizedValue)
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [])

  const onSubmit: SubmitHandler<UserFormInputs> = async formData => {
    if (!formData) {
      return alert({
        id: 'noFilledInputsUser',
        status: 'warning',
        title: 'Preencha todos os campos!'
      })
    }

    try {
      if (!isEditing) {
        await createUser({
          name: formData.username,
          login: formData.login,
          id_role: Number(formData.role?.value)
        })
        reset()
        onClose()
        updateUsersList()

        alert({
          id: 'successCreateUser',
          status: 'success',
          title: 'Usuário criado com sucesso'
        })
      } else {
        await updateUser(user.id, {
          name: formData.username,
          login: user.login,
          id_role: Number(formData.role?.value)
        })
        reset()
        onClose()
        updateUsersList()

        alert({
          id: 'successUpdateUser',
          status: 'success',
          title: 'Usuário atualizado com sucesso'
        })
      }
    } catch (err: any) {
      const errMsg = err?.response?.data?.message
      if (errMsg === 'login already exist') {
        const msg = formatErrorMessage(errMsg as ErrorMessage)
        setError('login', { message: msg ?? '' })
      } else {
        alert({
          id: `error${isEditing ? 'Update' : 'Create'}User`,
          status: 'error',
          title:
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente'
        })
      }
    }
  }

  useEffect(() => {
    if (isEditing) {
      reset({
        username: user.name,
        role: { label: user.role?.name, value: user.role?.id }
      })
    }
  }, [isEditing, user])

  return (
    <UserFormPresentation
      isOpen={isOpen}
      onClose={onClose}
      isEditing={isEditing}
      control={control}
      errors={errors}
      isLoadingCreate={isLoadingCreate}
      isLoadingUpdate={isLoadingUpdate}
      isValidFields={isValidFields}
      roles={roles?.map(({ id, name }) => ({ value: id, label: name }))}
      isLoadingRoles={isLoadingRoles}
      handleSubmit={handleSubmit(onSubmit)}
      resetForm={() => {
        reset({
          username: user?.name,
          role: { label: user?.role?.name, value: user?.role?.id }
        })
      }}
    />
  )
}
