import { Flex, Text, VStack } from 'components/atoms'
import { Modal, ReactSelect } from 'components/ui'
import { UncontrolledInput } from 'components/ui/Input'
import { UserFormInputs } from 'modules/users/presentation/validations/UserFormValidation'
import React from 'react'
import { Control, FieldErrors } from 'react-hook-form'
import { CancelButton } from 'templates/wallet/operations/wrapper'

import { ButtonContainer } from '../container/ButtonContainer'

interface RoleOption {
  value: number
  label: string
}

interface UserFormPresentationProps {
  isOpen: boolean
  onClose: () => void
  isEditing: boolean
  control: Control<UserFormInputs>
  isLoadingCreate: boolean
  isLoadingUpdate: boolean
  isValidFields: boolean
  roles: RoleOption[]
  isLoadingRoles: boolean
  handleSubmit: () => void
  resetForm: () => void
  errors: FieldErrors<{
    role: {
      label: string
      value: number
    }
    username: string
    login: string
  }>
}

export const UserFormPresentation = ({
  isOpen,
  onClose,
  isEditing,
  control,
  errors,
  isLoadingCreate,
  isLoadingUpdate,
  isValidFields,
  roles,
  isLoadingRoles,
  handleSubmit,
  resetForm
}: UserFormPresentationProps) => {
  const modalFooter = (
    <Flex alignItems="center" gap="16px">
      <CancelButton
        onClick={() => {
          onClose()
          resetForm()
        }}
      />

      <ButtonContainer
        title={!isEditing ? 'Criar usuário' : 'Salvar alterações'}
        isLoading={isLoadingCreate || isLoadingUpdate}
        onClick={handleSubmit}
        isDisabled={!isValidFields || isLoadingCreate || isLoadingUpdate}
      />
    </Flex>
  )

  return (
    <Modal
      heading={!isEditing ? 'Criar usuário' : 'Editar usuário'}
      footer={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      onCloseComplete={() => {
        onClose()
        resetForm()
      }}
    >
      <VStack spacing="xxs">
        <Text
          w="100%"
          color="#221C46"
          fontSize="16px"
          fontWeight="500"
          letterSpacing="-0.8px"
        >
          {isEditing
            ? 'Edite as informações do usuário:'
            : 'Preencha as informações do novo usuário:'}
        </Text>

        <UncontrolledInput
          isRequired
          id="username"
          height="48px"
          control={control}
          placeholder="Ex: João Pedro"
          title="Nome do usuário"
          onChange={() => undefined}
          errorMessage={errors?.username?.message ?? ''}
          isInvalid={!!errors?.username?.message}
        />

        {!isEditing && (
          <UncontrolledInput
            id="login"
            isRequired
            title="Login"
            height="48px"
            control={control}
            onChange={() => undefined}
            placeholder="login.ext@sodexo.com"
            errorMessage={errors?.login?.message ?? ''} // Passando a mensagem de erro opcional
            isInvalid={!!errors?.login?.message} // Indicando se o campo é inválido
          />
        )}

        <Flex w="100%">
          <ReactSelect
            id={'role'}
            isRequired
            width="464px"
            height="48px"
            control={control}
            title={'Tipo de perfil'}
            isDisabled={isLoadingRoles}
            placeholder="Selecione um perfil"
            options={roles}
          />
        </Flex>
      </VStack>
    </Modal>
  )
}
