/**
 * @example
 *   acceptedOnlyNumbers('123.456-789a!12', 11) // 12345678912
 *
 * @function acceptedOnlyNumbers
 * @param   {String} value
 * @param   {Number} maxLength
 * @returns {String} returns a string that contains only numbers
 */

export const acceptedOnlyNumbers = (
  value: string,
  maxLength: number
): string => {
  const formattedValue = value?.replace(/\D/g, '')
  return formattedValue.slice(0, maxLength)
}

/**
 * @example
 *   capitalizeSentence('john doe') // John Doe
 *
 * @function capitalizeSentence
 * @param   {String} sentence
 * @returns {String} returns a string with capitalized text
 */

export const capitalizeSentence = (sentence: string) => {
  const formattedSentence = sentence
    ?.toLowerCase()
    ?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())

  return formattedSentence
}

/**
 * Capitalizes each word in a sentence, while ignoring prepositions like "de", "da", "do", etc.
 *
 * @example
 *   capitalizeWordsExceptPrepositions('joão de sousa') // João de Sousa
 *   capitalizeWordsExceptPrepositions('maria das dores') // Maria das Dores
 *
 * @function capitalizeWordsExceptPrepositions
 * @param   {String} sentence - The sentence to be formatted
 * @returns {String} returns a string with capitalized text, while keeping prepositions lowercase
 */

export const capitalizeWordsExceptPrepositions = (sentence: string) => {
  const prepositions = ['de', 'da', 'do', 'das', 'dos', 'e', 'a', 'em']

  const formattedSentence = sentence
    ?.toLowerCase()
    ?.split(' ')
    .map((word, index) =>
      prepositions.includes(word) && index !== 0
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(' ')

  return formattedSentence
}
/**
 * @example
 *   limitString('Lorem Ipsum is simply dummy text of the printing and typesetting industry.', 11) // Lorem Ipsum...
 *
 * @function limitString
 * @param   {String} text
 * @param   {Number} maxLength
 * @returns {String} returns a string with a limited amount of characters
 */

export const limitString = (text: string, maxLength: number) => {
  if (text?.length > maxLength) {
    return `${text?.substring(0, maxLength)}...`
  }

  return text
}

type Event =
  | 'CREATED_ACCOUNT'
  | 'CREATED_CARD'
  | 'UNBLOCK_CARD'
  | 'BLOCK_CARD'
  | 'CREATED_VIRTUAL_CARD'
  | 'CANCEL_CARD'
  | 'ALTER_PASSWORD'

export const formatEventName = (event: Event) => {
  const data = {
    CREATED_ACCOUNT: 'Conta criada',
    CREATED_CARD: 'Cartão criado',
    UNBLOCK_CARD: 'Cartão desbloqueado',
    BLOCK_CARD: 'Cartao bloqueado',
    CREATED_VIRTUAL_CARD: 'Cartão virtual criado',
    CANCEL_CARD: 'Cartão cancelado',
    ALTER_PASSWORD: 'Senha alterada'
  }

  return data[event] ?? event
}
